import ApisConfig from '@/apis/api.config';
const prefix = ApisConfig.examApi + '/exam/web/';
export default {
  student: [
    {name: 'queryExam', method: 'post', url: prefix + 'student/exam/queryExam', header: null},
    {
      name: 'queryExamList',
      method: 'post',
      url: prefix + 'student/exam/queryExamList',
      header: null,
    },
    {
      name: 'queryExamDeadline',
      method: 'post',
      url: prefix + 'student/exam/queryExamDeadline',
      header: null,
    },
    {
      name: 'queryExamQuestionList',
      method: 'post',
      url: prefix + 'student/exam/queryExamQuestionList',
      header: null,
    },
    {
      name: 'submitQuestion',
      method: 'post',
      url: prefix + 'student/exam/submitQuestion',
      header: null,
    },
    {
      name: 'queryUserExamAnswerStateList',
      method: 'post',
      url: prefix + 'student/exam/queryUserExamAnswerStateList',
      header: null,
    },
    {name: 'submitExam', method: 'post', url: prefix + 'student/exam/submitExam', header: null},
    {
      name: 'queryUserExamScore',
      method: 'post',
      url: prefix + 'student/exam/queryUserExamScore',
      header: null,
    },
    {
      name: 'queryPaperQuestion',
      method: 'post',
      url: prefix + 'student/exam/queryPaperQuestion',
      header: null,
    },
    {
      name: 'queryExamStudent',
      method: 'post',
      url: prefix + 'student/exam/queryExamStudent',
      header: null,
    },
  ],
};
