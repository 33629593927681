export default {
  userModule: [
    {
      name: 'getUser',
      method: 'get',
      url: '/mock/user',
      header: null,
      mock: false,
    }, // 用户数据
  ],
};
