export default [
  {
    path: '/student/login',
    name: 'studentLogin',
    component: () => import('@views/studentModule/Login.vue'),
    meta: {title: '学生登录'},
  },
  {
    path: '/student',
    name: 'student',
    component: () => import('@views/studentModule/Layout.vue'),
    alwaysShow: true,
    children: [
      {
        path: 'exams',
        name: 'studentExams',
        component: () => import('@views/studentModule/ExamList.vue'),
        meta: {title: '考试列表'},
      },
      {
        path: 'exam',
        name: 'studentExam',
        component: () => import('@views/studentModule/Exam.vue'),
        meta: {title: '考试答题'},
      },
      {
        path: 'score',
        name: 'studentScore',
        component: () => import('@views/studentModule/Score.vue'),
        meta: {title: '考试成绩'},
      },
      {
        path: 'examAnalysis',
        name: 'studentExamAnalysis',
        component: () => import('@views/studentModule/Analysis.vue'),
        meta: {title: '作答详情'},
      },
    ],
  },
];
