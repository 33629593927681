<template>
  <div class="home">
    <p class="title">HelloWorld</p>
    <button @click="getHomeData()">请求首页数据</button>
    <button @click="updateHomeData()">更新首页数据</button><br />
    <button @click="getUserData()">请求用户数据</button>
    <img alt="Vue logo" src="../assets/images/logo.png" />
    <!-- <el-button>默认按钮</el-button>
    <el-button type="primary">主要按钮</el-button> -->
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="活动名称">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary">立即创建</el-button>
        <el-button>取消</el-button>
      </el-form-item>
    </el-form>
    <HelloWorld></HelloWorld>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {},
  data() {
    return {
      form: {
        // 表单数据
        name: '',
      },
    };
  },
  methods: {
    async getHomeData() {
      const result = await this.Api.home.getHomeList({name: '测试'});
      console.log(result);
    },
    async updateHomeData() {
      const result = await this.Api.home.updateHomeList({name: '测试'});
      console.log(result);
    },
    async getUserData() {
      const result = await this.Api.userModule.getUser({name: '测试'});
      console.log(result);
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: red;
  @include transform-centering;
}
</style>
