import router from './index';

// to是当前页面，from是上一个页面
// 路由跳转前
const noRedirectPath = ['/login', '/student/login', '/StatusError'];
const beforeEach = router.beforeEach((to, from, next) => {
  document.title = '考试平台';
  console.log("🚀 ~ beforeEach ~ to.path:", to.path)

  // 路由记录登录类型
  if (to.path == '/login') {
    window.localStorage.setItem('userType', 1); //1管理员
  }
  if (to.path == '/student/login') {
    window.localStorage.setItem('userType', 2); //1考生
  }

  if (noRedirectPath.includes(to.path)) return next();
  const tokenStr = window.localStorage.getItem('token');
  if (!tokenStr) {
    let type = window.localStorage.getItem('userType');
    let path = type == 1 ? '/login' : '/student/login';
    return next(path);
  }
  next();
});
// to是当前页面，from是上一个页面
// 路由跳转后
const afterEach = router.afterEach((to, from) => {});

export default {beforeEach, afterEach};
