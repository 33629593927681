export default [
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@views/adminModule/Layout.vue'),
    alwaysShow: true,
    children: [
      {
        path: 'exam-manage',
        name: 'ExamManage',
        component: () => import('@views/adminModule/ExamManage.vue'),
        children: [
          {
            path: '',
            redirect: '/admin/exam-manage/exam-list',
          },
          {
            path: 'exam-list',
            name: 'ExamList',
            component: () => import('@views/adminModule/exam/ExamList.vue'),
          },
          {
            path: 'add-exam/:examId',
            name: 'AddExam',
            component: () => import('@views/adminModule/exam/AddExam.vue'),
          },
          {
            path: 'import-student/:examId',
            name: 'ImportStudent',
            component: () => import('@views/adminModule/exam/ImportStudent.vue'),
          },
          {
            path: 'exam-results/:examId', //考试结果
            name: 'ExamResults',
            component: () => import('@views/adminModule/exam/ExamResults.vue'),
          },
        ],
      },
      {
        path: 'database-manage',
        name: 'DatabaseManage',
        component: () => import('@views/adminModule/DatabaseManage.vue'),
        children: [
          {
            path: '',
            redirect: '/admin/database-manage/questions',
          },
          {
            path: 'questions',
            name: 'DatabaseManage',
            component: () => import('@views/adminModule/tiku/QuestionManagement.vue'),
          },
          {
            path: 'questionStatic',
            name: 'QuestionStatic',
            component: () => import('@views/adminModule/tiku/QuestionStatic.vue'),
          },
          {
            path: 'autoVolumes',
            name: 'AutoVolumes',
            component: () => import('@views/adminModule/tiku/AutoVolumes.vue'),
          },
          {
            path: 'volumesRecords',
            name: 'VolumesRecords',
            component: () => import('@views/adminModule/tiku/VolumesRecords.vue'),
          },
          {
            path: 'manage-knowledge', //导入试题
            name: 'ManageKnowledge',
            component: () => import('@views/adminModule/tiku/ManageKnowledge.vue'),
          },
          // {
          //   path: 'import-records', //导入记录
          //   name: 'ImportRecords',
          //   component: () => import('@views/adminModule/tiku/ImportRecords.vue'),
          // },
        ],
      },
      {
        path: 'info-manage',
        name: 'InfoManage',
        component: () => import('@views/adminModule/InfoManage.vue'),
      },
      // {
      //   path: 'add-exam/:examId',
      //   name: 'AddExam',
      //   component: () => import('@views/adminModule/AddExam.vue'),
      // },
      // {
      //   path: 'import-student/:examId',
      //   name: 'ImportStudent',
      //   component: () => import('@views/adminModule/ImportStudent.vue'),
      // },
      // {
      //   path: 'exam-results/:examId', //考试结果
      //   name: 'ExamResults',
      //   component: () => import('@views/adminModule/ExamResults.vue'),
      // },
      {
        path: 'import-questions/:id', //导入试题
        name: 'ImportQuestions',
        component: () => import('@views/adminModule/tiku/ImportQuestions.vue'),
      },
      {
        path: 'import-records', //导入记录
        name: 'ImportRecords',
        component: () => import('@views/adminModule/tiku/ImportRecords.vue'),
      },
      {
        path: 'eidt-question/:id', //导入记录
        name: 'EidtQuestion',
        component: () => import('@views/adminModule/tiku/EidtQuestion.vue'),
      },
    ],
  },
];
