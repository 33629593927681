const examApi = {
  local: '/api',
  rc: '',
  development: '',
  test: '',
  production: '',
};

export default {
  examApi: examApi[process.env.NODE_ENV || 'local'],
};
