import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import StatusError from '@/views/Error';
import Login from '../views/Login.vue';

Vue.use(VueRouter);

// 加载所有模块子路由
const requireContext = require.context('./modules', false, /\.js$/);
let allRouterModules = [];
requireContext.keys().forEach((name) => {
  allRouterModules.push(...(requireContext(name).default || []));
});
  console.log("🚀 ~ requireContext.keys ~ allRouterModules:", allRouterModules)



const routes = [
  // {
  //   path: '/', // 首页
  //   name: 'Home',
  //   component: Home,
  // },
  {
    path: '/login', // 首页
    name: 'Login',
    component: Login,
  },
  {
    path: '/StatusError', // 状态错误页面
    name: 'StatusError',
    component: StatusError,
  },
  {
    path: '*',
    redirect: '/StatusError',
  },
  ...allRouterModules, // 注入所有的路由模块
];


const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;
