import ApisConfig from '@/apis/api.config';
const prefix = ApisConfig.examApi + '/exam/web/';
export default {
  exammanage: [
    {name: 'addExam', method: 'post', url: prefix + 'manage/exam/addExam', header: null},
    {name: 'updateExam', method: 'post', url: prefix + 'manage/exam/updateExam', header: null},
    {
      name: 'queryExamPage',
      method: 'post',
      url: prefix + 'manage/exam/queryExamPage',
      header: null,
    },
    {
      name: 'queryExamStudentPage',
      method: 'post',
      url: prefix + 'manage/exam/queryExamStudentPage',
      header: null,
    },
    {
      name: 'getStudentTemplate',
      method: 'get',
      url: prefix + 'manage/template/getStudentTemplate',
      header: null,
    },
    {
      name: 'importStudents',
      method: 'get',
      url: prefix + 'manage/exam/importStudents',
      header: null,
    },
    {
      name: 'queryExam',
      method: 'post',
      url: prefix + 'student/exam/queryExam',
      header: null,
    },
    {
      name: 'deleteExam',
      method: 'post',
      url: prefix + 'manage/exam/deleteExam',
      header: null,
    },
    {
      name: 'exportExamScoreList',
      method: 'get',
      url: prefix + 'manage/exam/exportExamScoreList',
      header: null,
    },
    {
      name: 'exportExamQuestionList',
      method: 'get',
      url: prefix + 'manage/exam/exportExamQuestionList',
      header: null,
    },
    {
      name: 'queryExamScorePage',
      method: 'post',
      url: prefix + 'manage/exam/queryExamScorePage',
      header: null,
    },
    {
      name: 'getPapers',
      method: 'get',
      url: prefix + 'tk/paper/getPapers',
      header: null,
    },
  ],
  databaseManage: [
    {
      name: 'getKnowledgeTree',
      method: 'get',
      url: prefix + 'tk/knowledge/getKnowledgeTree',
      header: null,
    },
    {name: 'addKnowledge', method: 'post', url: prefix + 'tk/knowledge/addKnowledge', header: null},
    {
      name: 'updateKnowledge',
      method: 'post',
      url: prefix + 'tk/knowledge/updateKnowledge',
      header: null,
    },
    {name: 'delKnowledge', method: 'post', url: prefix + 'tk/knowledge/delKnowledge', header: null},
    {name: 'upload', method: 'post', url: prefix + 'tk/question/upload', header: null},
    {
      name: 'searchQuestion',
      method: 'post',
      url: prefix + 'tk/question/searchQuestion',
      header: null,
    },
    {name: 'addQuestion', method: 'post', url: prefix + 'tk/question/addQuestion', header: null},
    {
      name: 'deleteQuestion',
      method: 'post',
      url: prefix + 'tk/question/deleteQuestion',
      header: null,
    },
    {
      name: 'updateQuestion',
      method: 'post',
      url: prefix + 'tk/question/updateQuestion',
      header: null,
    },
    {name: 'tkStatistics', method: 'get', url: prefix + 'tk/question/tkStatistics', header: null},
    {name: 'prePaper', method: 'post', url: prefix + 'tk/paper/prePaper', header: null},
    {name: 'getPaper', method: 'post', url: prefix + 'tk/paper/getPaper', header: null},
    {name: 'getPrePaper', method: 'post', url: prefix + 'tk/paper/getPrePaper', header: null},
    {name: 'getPaperDetial', method: 'post', url: prefix + 'tk/paper/getPaperDetial', header: null},
    {name: 'savePaper', method: 'post', url: prefix + 'tk/paper/savePaper', header: null},
    {
      name: 'getQuestionById',
      method: 'post',
      url: prefix + 'tk/question/getQuestionById',
    },
    {
      name: 'delPaper',
      method: 'post',
      url: prefix + 'tk/paper/delPaper',
    },
    {
      name: 'getQuestionCount',
      method: 'post',
      url: prefix + 'tk/paper/getQuestionCount',
    },
  ],
};
